<template>
  <v-app>
    <!-- This renders different layouts depending on the route.meta.layout property -->
    <component :is="layout">
      <v-main>
        <router-view />
      </v-main>
    </component>

    <BaseFooter />
  </v-app>

  <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.type"
    :location="snackbar.configs.location"
    :variant="snackbar.configs.variant"
  >
    {{ snackbar.message }}
    <template v-slot:actions>
      <v-btn :color="snackbar.configs.buttonColor" variant="plain" @click="closeSnackbar">
        &#10005; <!-- html code for 'x' -->
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { onMounted, computed, markRaw, watch, ref, shallowRef, defineAsyncComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import BaseFooter from './BaseFooter.vue'
import BasicLayout from '@/pages/common/BasicLayout.vue'
import { useAuthStore } from '@/stores/auth.store'
import { useSnackbarStore } from '@/stores/snackbar.store'
import { showSnackbarInternalError } from '@/utils/showSnackbar'

// NOTE: Prefer 'ref' over 'reactive'. Only use 'reactive' for maintaining state (with many properties) - like 'data'
//       in Vue 2.

// List roles in order of prominence
const allRoles = ['engineer', 'owner', 'admin', 'manager', 'player', 'guest']

const authStore = useAuthStore()
const snackbar = useSnackbarStore()
const router = useRouter()
const route = useRoute()
// const layout = ref()
const layout = shallowRef(BasicLayout)

const loggedIn = computed(() => authStore.isAuthenticated)
const userRoles = computed(() => authStore.userRoles || [])
// const currentUser = computed(() => authStore.currentUser)
// const fullName = computed(() => currentUser.value.full_name)

// Find the user's most prominent role
const primaryRole = computed(() => {
  return allRoles.find((role) => userRoles.value.includes(role))
})

const closeSnackbar = () => {
  snackbar.show = false
}

watch(
  () => route.meta,
  async (meta) => {
    try {
      const component =
        meta.layout && defineAsyncComponent(() => import(/* @vite-ignore */ `@/pages/common/${meta.layout}.vue`))
      layout.value = component
    } catch (e) {
      layout.value = BasicLayout
    }
  },
  { immediate: true }
)

const fetchCurrentUser = () => {
  authStore
    .fetchCurrentUser()
    .then((response) => {
      authStore
        .getUserAccounts()
        .then((response) => {
          // success (flash) message
        })
        .catch((error) => {
          // TODO: page won't load if there's an error; redirect somewhere??
          // showSnackbarInternalError(`Something went wrong when fetching the user: ${error}`)
        })
    })
    .finally(() => {
      // success (flash) message
    })
    .catch((error) => {
      // TODO: page won't load if there's an error; redirect somewhere??
      // showSnackbarInternalError(`Something went wrong when fetching the user: ${error}`)
    })
};

onMounted(() => {
  // load user data via ajax
  if (!loggedIn.value) {
    fetchCurrentUser()
  }
})
</script>

<style lang="scss">
@import './../assets/scss/settings.scss'
</style>
